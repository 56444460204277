<template>
  <CRow v-if="semesterSum">
    <CCol lg="6">
      <CCard>
        <CCardBody class="bg-yellow">
          <div class="row icon-row">
            <div class="col-10">
              <strong>รายการปรับคะแนนพฤติกรรม</strong><br />
              <span class="text-white"
                >ในเดือน {{ nowMonth }} {{ nowYear }}:</span
              ><br />
              <span class="text-white" style="font-size: 29px"
                >{{ monthSum }} รายการ</span
              >
            </div>
            <div class="col-2">
              <router-link :to="'/statByMonth'" class="text-white"
                ><i
                  class="ri-arrow-right-circle-line"
                  style="font-size: 36px"
                ></i
              ></router-link>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol lg="6">
      <CCard>
        <CCardBody class="bg-yellow">
          <div class="row icon-row">
            <div class="col-10">
              <strong>สรุปคะแนนพฤติกรรมรายบุคคล</strong><br />
              <i class="ri-user-line" style="font-size: 36px"></i>
            </div>
            <div class="col-2">
              <router-link :to="'/SumScore'" class="text-white"
                ><i
                  class="ri-arrow-right-circle-line"
                  style="font-size: 36px"
                ></i
              ></router-link>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <!--
    <CCol lg="4">
      <CCard>
        <CCardBody class="bg-yellow">
          <div class="row icon-row">
            <div class="col-10">
              <strong>กลุ่มเสี่ยงไม่จบการศึกษา</strong><br />
              <span style="font-size: 50px; color: red"><i class="ri-user-fill"></i> </span>
            </div>
            <div class="col-2 text-right">
              <router-link :to="'/statByRisk'" class="text-white"
                ><i
                  class="ri-arrow-right-circle-line"
                  style="font-size: 36px"
                ></i
              ></router-link>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol lg="4" v-if="appointmantData">
      <CCard>
        <CCardBody class="bg-yellow">
          <div class="row icon-row">
            <div class="col-10">
              <strong>รายการแก้ไขพฤติกรรม</strong><br />
              <span class="text-white" style="font-size: 29px"
                >{{ appointmantData.count }} รายการ</span
              >
            </div>
            <div class="col-2">
              <router-link :to="'/appStatAll'" class="text-white"
                ><i
                  class="ri-arrow-right-circle-line"
                  style="font-size: 36px"
                ></i
              ></router-link>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
        -->
  </CRow>
  <div v-else>
    <div class="spinner-border text-secondary" role="status">
      <span class="sr-only"></span>
    </div>
    <div>Loading...</div>
  </div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";
import Datepicker from "vuejs-datepicker";
import { Printd } from "printd";

export default {
  name: "WidgetsDropdown",
  components: { CChartLineSimple, CChartBarSimple, Datepicker, Printd },
  data() {
    return {
      semesterSum: null,
      sumData: null,
      monthSum: null,
      nowMonth: null,
      nowYear: null,
      appointmantData: null,
      userData: this.$cookies.get("user"),
      levelInfo: null,
      classInfo: null,
    };
  },
  mounted() {
    //FETCH CLASS DROPDOWN
    this.axios
      .get(
        this.$hostUrl +
          "php_action/classAPI.php?txtAction=getAll&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        this.classInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});
    //FETCH LEVEL DROPDOWN
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAll")
      .then((response) => {
        this.levelInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});

    //CHECK NOTIFICATION
    this.axios
      .get(
        this.$hostUrl +
          "php_action/appointmentAPI.php?txtAction=getNotify&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        return response;
        //console.log(response.data);
      })
      .finally(() => {});

    //FETCH Semester Stats

    this.axios
      .get(
        this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qSemester=all&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        //console.log(response.data);
        this.semesterSum = response.data.mainData;
        //console.log(this.semesterSum);
      })
      .finally(() => {});

    // FETCH MONTH STATS
    let dateObj = new Date();
    let cDate = dateObj.toISOString().substring(0, 10);
    this.axios
      .get(
        this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=getRecordInMonth&schoolId=" +
          this.userData.user_address +
          "&txtDate=" +
          cDate
      )
      .then((response) => {
        //console.log(response.data.mainData);
        this.monthSum = response.data.mainData.length;
      })
      .finally(() => {});

    //FETCH APPOINTMENT IN SEMESTER BY SCHOOL
    this.axios
      .get(
        this.$hostUrl +
          "php_action/appointmentAPI.php?txtAction=getAll&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        this.appointmantData = response.data;
        //console.log(this.appointmantData);
      })
      .finally(() => {});

    //DATE DATA
    let d = new Date();
    this.nowYear = d.getFullYear() + 543;
    var thmonth = new Array(
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    );
    this.nowMonth = thmonth[d.getMonth()];
  },
  methods: {
    getClassroomStat() {
      const form = document.getElementById("formSearchClassroom");
      const formData = new FormData(form);
      formData.append("txtAction", "getScoreByClassroom");
      formData.append("schoolId", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.sumData = response.data;
          console.log(this.sumData);
        })
        .finally(() => {});
    },
    printD() {
      const cssText = `
  h1 h4 {
    color: black;
    font-family: kanit;
  }
  .text-red{
    color: red;
  }
.table th,
.table td {

    vertical-align: top;
    font-size: 0.5rem;
    font-family: kanit;
    border: 1px solid black !important;
}
table{
  width:100% !important;
  border-spacing: 0px !important;
}
.sign tr{
  border: none !important;
}
.bg-red{
    background-color: #ef5550;
}
h4,
.h4 {
    font-size: 1rem
}
h5,
.h5 {
    font-size: 1.25rem
}
h3,
.h3 {
    font-size: 1.25rem;
    font-family: kanit
}
.text-right {
    text-align: right !important
}
.text-center {
    text-align: center !important;
    font-family: kanit;
}

`;
      const d = new Printd();
      d.print(document.getElementById("single-info"), [cssText]);
    },
  },
};
</script>
