<template>
  <CCard style="margin-top: 20px">
    <CCardHeader class="bg-skw-blue text-white"
      ><strong>สรุปรายงานการปฏิบัติหน้าที่สอน</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol lg="12">
          <div class="relative w-full mb-3" v-if="userData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่:
            </label>
            <datepicker
              name="d"
              input-class="form-control bg-white"
              format="yyyy-MM-dd"
              placeholder="คลิ๊ก"
              v-model="d"
            ></datepicker>
          </div>
        </CCol>

        <CCol lg="12">
          <div class="text-right">
            <button class="btn btn-yellow" @click="getReport">ค้นหา</button>
          </div>
        </CCol>
        <CCol lg="12" v-if="LoadingState == true">Loading.....</CCol>
        <CCol lg="12" v-if="logInfo">
          <div>
            <div v-for="log in logInfo" :key="log.id">
              <div v-if="log.teachingLog.length == 0" class="text-danger">
                #{{ log.user_id }}: {{ log.user_fname }} {{ log.user_lname }}
                {{ log.teachingLog.length }} ราการ
              </div>
              <div v-else class="text-black" style="margin-top:10px">
                #{{ log.user_id }}: {{ log.user_fname }} {{ log.user_lname }}
                {{ log.teachingLog.length }} ราการ
              </div>
              <div v-if="log.teachingLog.length > 0">
                <div v-for="classLog in log.teachingLog" :key="classLog.id">
                  <div style="margin:10px">
                    <strong>หมายเลขบันทึก: {{ classLog.teaching_log_id }}</strong><br />
                    ห้อง: {{ classLog.level_abv_name }}/{{
                      classLog.teaching_std_class
                    }}
                    ช่องทางการสอน: {{ classLog.route_name }} <br />
                    วิชา: {{ classLog.subject_code }}
                    {{ classLog.subject_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
    <!-------------------MODAL CREATE--------------------------------------------------------------------------------------->
    <form id="formCreateLog" v-on:submit.prevent="createLog" method="POST">
      <CModal
        :show.sync="modalCreate"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow
          ><!--body-->

          <CCol lg="12">
            <SltSubject />
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                วันที่
              </label>
              <datepicker
                name="txtDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <ChkPeriod :modalType="'create'" />
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนทั้งหมด (คน)
              </label>
              <input
                type="number"
                name="numStdAmount"
                class="form-control"
                required
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่เข้าเรียน (คน)
              </label>
              <input
                type="number"
                name="numStdAttended"
                class="form-control"
                required
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่ขาด/ ลา
              </label>
              <SltStudent /></div
          ></CCol>

          <CCol lg="12" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ผลการจัดการเรียนการสอน ปัญหา และข้อเสนอแนะ:
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlText1"
                name="areaSuggest"
                rows="3"
              ></textarea></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ภาพประกอบรายงาน:</strong>
              </label>
              <button
                type="button"
                class="btn btn-warning text-white"
                @click="modalUploadPic = true"
              >
                อัพโหลดไฟล์จากเครื่อง
              </button>
              <div
                v-for="n in 4"
                :key="n.id"
                class="bg-secondary el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
              >
                <form
                  :id="'formPic' + n"
                  v-on:submit.prevent="uploadFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ภาพที่ {{ n }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControlFile' + n"
                      :name="'file' + n"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 1:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl1"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 2:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl2"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 3:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl3"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ภาพที่ 4:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pictUrl4"
                />
              </div>
            </div>
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ไฟล์ประกอบรายงาน:</strong>
              </label>
              <div
                v-for="o in 8"
                :key="o.id"
                class="bg-yellow el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
                v-if="o > 4"
              >
                <form
                  :id="'formFile' + o"
                  v-on:submit.prevent="uploadFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ไฟล์ที่ {{ o - 4 }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControl' + o"
                      :name="'file' + o"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 1:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink1"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 2:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink2"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 3:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink3"
                />
              </div>
              <div class="input-group mb-3" style="margin-top: 20px">
                <div class="input-group-prepend">
                  <span class="input-group-text">ไฟล์ที่ 4:</span>
                </div>
                <input
                  type="text"
                  name="fileLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="fileLink4"
                />
              </div></div
          ></CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">
              บันทึกการสอนของ: {{ userData.user_fname }}
            </h3>
          </h6>
          <CButtonClose @click="modalCreate = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalCreate = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL EDIT--------------------------------------------------------------------------------------->
    <form id="formUpdateLog" v-on:submit.prevent="updateLog" method="POST">
      <CModal
        :show.sync="modalEdit"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="danger"
      >
        <CRow v-if="$store.state.selectedTeachingLog"
          ><!--body-->

          <CCol lg="6" v-if="levelInfo">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ห้อง/ กลุ่ม:
              </label>
              <select
                name="sltClass"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_class"
              >
                <option value="">-----เลือก-----</option>
                <option
                  v-for="classNo in 17"
                  :key="classNo.id"
                  :value="classNo"
                >
                  {{ classNo }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol lg="12">
            <SltSubject />
          </CCol>
          <CCol lg="6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                วันที่
              </label>
              <datepicker
                name="txtDate"
                input-class="form-control bg-white"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม"
                v-model="$store.state.selectedTeachingLog.teaching_date"
              ></datepicker>
            </div>
          </CCol>
          <CCol lg="6">
            <ChkPeriod :modalType="'edit'" />
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนทั้งหมด (คน)
              </label>
              <input
                type="number"
                name="numStdAmount"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_amount"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่เข้าเรียน (คน)
              </label>
              <input
                type="number"
                name="numStdAttended"
                class="form-control"
                required
                v-model="$store.state.selectedTeachingLog.teaching_std_attended"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                นักเรียนที่ขาด/ ลา
              </label>
              <SltStudent /></div
          ></CCol>

          <CCol lg="12" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ผลการจัดการเรียนการสอน ปัญหา และข้อเสนอแนะ:
              </label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                name="areaSuggest"
                rows="3"
                v-model="$store.state.selectedTeachingLog.teaching_problem"
              ></textarea></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                <strong>ภาพประกอบรายงาน</strong>
              </label>
              <div
                v-for="n in 4"
                :key="n.id"
                class="bg-secondary el-shadow text-black"
                style="padding: 20px; margin-bottom: 5px; border-radius: 5px"
              >
                <form
                  :id="'formUpdatePic' + n"
                  v-on:submit.prevent="editFile"
                  method="POST"
                >
                  <div class="form-group">
                    <label for="exampleFormControlFile1"
                      >Upload ภาพที่ {{ n }}:</label
                    >
                    <input
                      type="file"
                      class="form-control-file form-control-sm"
                      :id="'exampleFormControlFile' + n"
                      :name="'file' + n"
                      required
                    />
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-warning btn-sm">
                      upload
                    </button>
                  </div>
                </form>
              </div>

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link1:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic1New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link2:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic2New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link3:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic3New"
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Link4:</span>
                </div>
                <input
                  type="text"
                  name="picLink[]"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  v-model="pic4New"
                />
              </div></div
          ></CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title" v-if="$store.state.selectedTeachingLog">
            <h3 class="text-3xl font-semibold">
              แก้ไขบันทึกการสอนหมายเลข:
              {{ $store.state.selectedTeachingLog.teaching_log_id }}
            </h3>
          </h6>
          <CButtonClose @click="modalEdit = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalEdit = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL SEARCH FOR PRINT--------------------------------------------------------------------------------------->
    <form
      id="formSearchForPrint"
      v-on:submit.prevent="searchForPrint"
      method="POST"
    >
      <CModal
        :show.sync="modalPrint"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="warning"
      >
        <CRow><!--body--> </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">ปริ้นบันทึกการสอน:</h3>
          </h6>
          <CButtonClose @click="modalPrint = false" class="text-white" />
        </template>

        <template #footer>
          ----
          <!--
          <CButton @click="modalPrint = false" color="danger">Discard</CButton>

          -->
        </template>
      </CModal>
    </form>

    <!-------------------MODAL ALERT------------------------>
    <CModal
      :show.sync="modalAlert"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="danger"
    >
      {{ msgAlert }}
      <template #header>
        <h6 class="modal-title">
          <h3 class="text-3xl font-semibold">คำเตือน</h3>
        </h6>
        <CButtonClose @click="modalAlert = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalAlert = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "../components/SltSubject.vue";
import SltStudent from "../components/SltStudent.vue";
import ChkPeriod from "../components/ChkPeriod.vue";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    ChkPeriod,
  },
  data() {
    return {
      columns: [
        {
          label: "#",
          field: "teaching_log_id",
          type: "number",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
          type: "text",
        },

        {
          label: "ระดับชั้นที่สอน",
          field: "level_abv_name",
          type: "text",
        },
        {
          label: "ห้อง/ กลุ่ม",
          field: "teaching_std_class",
          type: "number",
        },
        {
          label: "ช่องทางการสอน",
          field: "route_name",
          type: "text",
        },
        {
          label: "วันที่สอน",
          field: "teaching_date",
        },
        {
          label: "นักเรียนทั้งหมด (คน)",
          field: "teaching_std_amount",
        },
        {
          label: "นักเรียนที่เข้าเรียน (คน)",
          field: "teaching_std_attended",
        },
        {
          label: "การนิเทศ",
          field: "teaching_who_comment",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      responseContent: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      modalUploadPic: false,
      modalPrint: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      file1New: null,
      file2New: null,
      file3New: null,
      file4New: null,
      handyAttachments: [],
      logInfo: null,
      d: null,
      loadingState:false
    };
  },
  mounted() {
    //console.log(this.userData);
  },
  methods: {
    openModal(appId) {
      this.modalCreate = true;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/appointmentAPI.php?txtAction=getSingleAppointment&appId=" +
            appId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedAppointment = response.data.mainData;
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    createLog(event) {
      //SEND DATA
      if (!this.$store.state.selectedSubject) {
        alert("กรุณาเลือกรายวิชา");
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append("txtLessonSubjectID", 0);
      formData.append("txtSubjectClassID", 0);
      formData.append("txtSubLessonID", 0);
      formData.append("txtSubjectTechID", 0);
      formData.append("txtClassSchoolID", 0);
      formData.append("txtRoom", 0);
      if (this.$route.query.LessonSubjectID) {
        formData.append(
          "txtLessonSubjectID",
          this.$route.query.LessonSubjectID
        );
      }
      if (this.$route.query.SubjectClassID) {
        formData.append("txtSubjectClassID", this.$route.query.SubjectClassID);
      }
      if (this.$route.query.SubLessonID) {
        formData.append("txtSubLessonID", this.$route.query.SubLessonID);
      }
      if (this.$route.query.SubjectTechID) {
        formData.append("txtSubjectTechID", this.$route.query.SubjectTechID);
      }
      if (this.$route.query.ClassSchoolID) {
        formData.append("txtClassSchoolID", this.$route.query.ClassSchoolID);
      }
      if (this.$route.query.Room) {
        formData.append("txtRoom", this.$route.query.Room);
      }

      //console.log(this.$store.state.selectedStudent);
      formData.append(
        "students",
        JSON.stringify(this.$store.state.selectedStudent)
      );
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    updateLog(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      //console.log(this.$store.state.selectedStudent);
      formData.append(
        "students",
        JSON.stringify(this.$store.state.selectedStudent)
      );
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },

    removeLog(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/teachingAPI.php?txtAction=delete&userId=" +
              this.userData.user_id +
              "&logId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    getReport() {
      if (!this.d || this.d == "") {
        alert("กรุณาระบุวันที่");
        return false;
      }
      this.loadingState = true;
      let formData = new FormData();
      formData.append("txtAction", "getTeachingLogByDateTeacher");
      formData.append("schoolCode", this.userData.user_address);
      formData.append("d", this.dateConverter(this.d));

      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData)
        .then((response) => {
          let r = response.data;
          //console.log(r);
          //console.log(r.mainData);
          //console.log(typeof this.logInfo);
          this.logInfo = r.mainData;
          this.loadingState = false;
        });
    },
    dateConverter(dateInput) {
      let day = dateInput.getDate(); //Date of the month: 2 in our example
      let month = dateInput.getMonth() + 1; //Month of the Year: 0-based index, so 1 in our example
      let year = dateInput.getFullYear();
      return (
        year +
        "-" +
        String(month).padStart(2, "0") +
        "-" +
        String(day).padStart(2, "0")
      );
    },
    openModalEdit(data) {
      this.modalEdit = true;
      this.axios
        .get(
          this.$hostUrl +
            "php_action/teachingAPI.php?txtAction=getTeachingLogByID" +
            "&logId=" +
            data
        )
        .then((response) => {
          let jsonSubject = {
            code: response.data.mainData.teaching_subject,
            label:
              response.data.mainData.subject_code +
              " " +
              response.data.mainData.subject_name,
          };
          let jsonPeriod = JSON.parse(response.data.mainData.teaching_periods);
          let jsonMissingStd = JSON.parse(
            response.data.mainData.teaching_std_missing
          );
          let jsonPicLink = JSON.parse(
            response.data.mainData.teaching_pictures
          );
          response.data.mainData.jsonPicLink = jsonPicLink;
          let jsonFileLink = JSON.parse(response.data.mainData.teaching_files);
          response.data.mainData.jsonFileLink = jsonFileLink;
          this.$store.commit("setSelectedTeachingLog", response.data.mainData);
          this.$store.commit("setSelectedSubject", jsonSubject);
          this.$store.commit("setCheckedPeriod", jsonPeriod);
          this.$store.commit("setSelectedStudent", jsonMissingStd);
          console.log(this.$store.state.selectedTeachingLog);

          if (this.$store.state.selectedTeachingLog) {
            this.pic1New = this.$store.state.selectedTeachingLog.jsonPicLink[0];
            this.pic2New = this.$store.state.selectedTeachingLog.jsonPicLink[1];
            this.pic3New = this.$store.state.selectedTeachingLog.jsonPicLink[2];
            this.pic4New = this.$store.state.selectedTeachingLog.jsonPicLink[3];
            this.file1New =
              this.$store.state.selectedTeachingLog.jsonFileLink[0];
            this.file2New =
              this.$store.state.selectedTeachingLog.jsonFileLink[1];
            this.file3New =
              this.$store.state.selectedTeachingLog.jsonFileLink[2];
            this.file4New =
              this.$store.state.selectedTeachingLog.jsonFileLink[3];
          }
        })
        .finally(() => {});
    },
    uploadFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uppoaded");
            this.pictUrl1 = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uppoaded");
            this.pictUrl2 = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uppoaded");
            this.pictUrl3 = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uppoaded");
            this.pictUrl4 = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uppoaded");
            this.fileLink1 = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uppoaded");
            this.fileLink2 = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uppoaded");
            this.fileLink3 = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uppoaded");
            this.fileLink4 = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => {});
    },
    editFile(event) {
      //SEND DATA
      //alert(event.target.id);
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createUploadPic");
      this.axios
        .post(this.$hostFileUrl + "py_action/fileAPI.py", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.message.file1Url) {
            alert("picture1 is uploaded");
            this.pic1New = this.$hostFileUrl + response.data.message.file1Url;
          } else if (response.data.message.file2Url) {
            alert("picture2 is uploaded");
            this.pic2New = this.$hostFileUrl + response.data.message.file2Url;
          } else if (response.data.message.file3Url) {
            alert("picture3 is uploaded");
            this.pic3New = this.$hostFileUrl + response.data.message.file3Url;
          } else if (response.data.message.file4Url) {
            alert("picture4 is uploaded");
            this.pic4New = this.$hostFileUrl + response.data.message.file4Url;
          } else if (response.data.message.file5Url) {
            alert("file1 is uploaded");
            this.file1New = this.$hostFileUrl + response.data.message.file5Url;
          } else if (response.data.message.file6Url) {
            alert("file2 is uploaded");
            this.file2New = this.$hostFileUrl + response.data.message.file6Url;
          } else if (response.data.message.file7Url) {
            alert("file3 is uploaded");
            this.file3New = this.$hostFileUrl + response.data.message.file7Url;
          } else if (response.data.message.file8Url) {
            alert("file4 is uploaded");
            this.file4New = this.$hostFileUrl + response.data.message.file8Url;
          }
        })
        .finally(() => {});
    },
  },
};
</script>
