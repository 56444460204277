<template>

    <CRow>

      <CCol col="12" sm="6" lg="4" @click="$router.push({ path: 'wfh' })">
        <CWidgetIcon
          header=""
          text="ลงเวลาปฏิบัติงาน"
          color="gradient-info"
          :icon-padding="false"
          link="wfh"
        >
          <CIcon name="cil-laptop" class="mx-5" width="24" />
        </CWidgetIcon>
      </CCol>

    </CRow>







</template>

<script>
import WidgetsBrand from "./WidgetsBrand";
import WidgetsDropdown from "./WidgetsDropdown";
import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";


export default {
  name: "Widgets",
  components: {
    CChartLineSimple,
    CChartBarSimple,
    WidgetsBrand,
    WidgetsDropdown,
  },
};
</script>
