<template>
  <div>
    <!--STUDENT AREA-->
    <CRow>
      <CCol lg="12" class="text-center">
        <CButtonGroup v-if="userData.std_ordinal">
          <CButton color="warning" @click="$router.push({ name: 'Profile' })"
            >อัพเดทข้อมูลส่วนบุคคล</CButton
          >
          <CButton color="dark" @click="openLink('http://skwims.com/')"
            >ตรวจสอบผลการเรียน skwims</CButton
          >
          <CButton color="warning" @click="openLink('http://61.19.148.41/pp1/')"
            >ตรวจสอบผลการเรียน Semester</CButton
          >
        </CButtonGroup>
      </CCol>
    </CRow>
    <TblMyScore v-if="userData.std_ordinal" />
    <TblMyEnrollment v-if="userData.std_ordinal" />
    <TblMyDeposit v-if="userData.std_ordinal" />

    <!--ALL ACCESS-->

    <CRow>
      <CCol lg="12">
        <TblCovidByDate />
      </CCol>
      <CCol lg="12"> <TblTeachingLogBySchoolDate /></CCol>
    </CRow>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";

import WidgetWorkFromHome from "./widgets/WidgetWorkFromHome";
import WidgetFlag from "./widgets/WidgetFlag.vue";
import WidgetStd from "./widgets/WidgetStd.vue";
import TblMyScore from "./components/TblMyScore";

import TblCovidByDate from "./components/TblCovidByDate";
import TblMyEnrollment from "./components/TblMyEnrollment";
import TblMyDeposit from "./components/TblMyDeposit";
import TblTeachingLogBySchoolDate from "./components/TblTeachingLogBySchoolDate";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetWorkFromHome,
    WidgetFlag,
    WidgetStd,
    TblMyScore,
    TblCovidByDate,
    TblMyEnrollment,
    TblMyDeposit,
    TblTeachingLogBySchoolDate,
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {
    openLink(targetUrl) {
      window.open(targetUrl);
    },
  },
  mounted() {
    //console.log(this.userData);
  },
};
</script>
